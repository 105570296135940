import React from "react"
import BorderWrapper from "react-border-wrapper"

import img from "../images/quarter-logo-mini.png";
import screenshot from "../images/Screenshot-2019-08-19-16.31.40.png";

const topElement = `📼`;
const rightElement = `🦜`;
const emailElement = `📧`;

export default function () {
  return (
    <div id="quarter">
      <BorderWrapper borderColour="#f2dbd0">
        <aside id="scoreboard__about">
          <h2>Quarter</h2>
          <h5>is an Accounting App for Freelancers</h5>
          <h6>from <a href="https://pacificio.com">pacific IO</a></h6>
          <p>
            <img src={img} id="qLogoImgSplash" alt="Quarter logo" />
          </p>
          <p>
            <b>Latest Preview versions:</b>
          </p>
          <ul id="appVersions">
            <li>
              <a href="https://djmblog.com/assets/files/Quarter-0.11.3.dmg">
                0.11.3 for Mac
            </a>
            </li>
            <li>
              <a href="https://djmblog.com/assets/files/QuarterSetup0.11.3-windows.exe">
                0.11.3 for Windows
            </a>
            </li>
          </ul>
        </aside>
      </BorderWrapper>
      <br className="betweenSpacer" />
      <br className="betweenSpacer" />
      <BorderWrapper
        borderColour="#fcc292"
        borderWidth="10px"
        borderRadius="11px"
        borderType="solid"
        innerPadding="30px"
        topElement={topElement}
        topPosition={0.05}
        topOffset="22px"
        topGap="4px"
        rightElement={rightElement}
        rightPosition={0.1}
        rightOffset="22px"
        rightGap="4px"
      >
        <h3 id="videoH3"><a href="https://www.youtube.com/watch?v=beZr2RqNDiY" id="ytLinkh3">View a short video about Quarter</a></h3>
        <aside id="videoLinkQuarterScreencast">
          <a href="https://www.youtube.com/watch?v=beZr2RqNDiY" id="ytLink">
            <img id="screenshotQuarterYoutube" alt="screenshot of Quarter screencast video" src={screenshot} />
          </a>
        </aside>
      </BorderWrapper>
      <BorderWrapper
      borderColour="#fcc292"
      borderWidth="9px"
      borderRadius="9px"
      borderType="solid"
      innerPadding="30px"
      rightElement={emailElement}>
        <h4 id="updated">Stayed Updated!</h4>
        <p id="emailLink">
          Get the latest info about pacific IO software <a href="https://pacificpelican.us9.list-manage.com/subscribe?u=32b56b1c860aa1eacf5411ad0&id=66b7185ce9">via the email list</a>.
        </p>
      </BorderWrapper>
      <BorderWrapper
      borderColour="#fcc292"
      borderWidth="9px"
      borderRadius="9px"
      borderType="solid"
      innerPadding="30px">
        <div id="social">
          <h4>Share on social media</h4>
          <blockquote class="twitter-tweet"><p lang="en" dir="ltr">Quarter Accounting App for Freelancers preview version now available: <a href="https://t.co/G0Ox0eaHJy">https://t.co/G0Ox0eaHJy</a></p>&mdash; Dan McKeown (@pacificpelican) <a href="https://twitter.com/pacificpelican/status/1159132695240175618?ref_src=twsrc%5Etfw">August 7, 2019</a></blockquote> <script async src="https://platform.twitter.com/widgets.js" charset="utf-8"></script>
        </div>
      </BorderWrapper>
      <style>
        {`
        div#quarter h6 a, h3#videoH3 a {
          color: inherit;
        }
        div#quarter #appVersions {
          background: #fffbf9;
        }
        div#quarter ul a {
          color: #ed936d;
        }
      `}
      </style>
    </div>
  )
}
